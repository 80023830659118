import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import { useFormContext } from "react-hook-form";

import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/outline'
import {createTicket} from "../actions/tickets";

import CouponCode from "./payment/CouponCode";
import SwishForm from "./payment/SwishForm";
import CheckoutForm from "./payment/CheckoutForm";
import Spinner from "./Spinner.js";
import AcceptTerms from "./payment/AcceptTerms";
import { PaymentRetrieval } from "./payment/swishHelpers/PaymentRetrieval.js";

const paymentMethods = [
    {
        method: "Ange rabattkod",
        component: CouponCode,
    },
    {
        method: "Kort",
        component: CheckoutForm,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PaymentMethods() {
    const train = useSelector(state => state.trains.currentTrain);
    const station = useSelector(state => state.activeStation);
    const travelers = useSelector(state => state.travelers);
    const { seats } = useSelector(state => state.activeSeats);
    const price = useSelector(state => state.price);
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState('');
    let history = useHistory();
    const discount = useSelector(state => state.discount);
    let $HR = PaymentRetrieval;

    const {handleSubmit} = useFormContext();

    const createTickets = async (data, ev) => {
        setProcessing(true);
        dispatch(createTicket({
            train,
            station,
            seats,
            travelers: data,
            discount: 
            {
                code: (discount) ? discount.code : null
            },
            payment: 
            {
                status: 'N/A',
                id: 'N/A',
                reference: 'N/A',
                method: $HR.PaymentMethod.COUPON
            }
        }));
        setProcessing(false);
        history.push('/confirmation');
    };

    const onSubmit = (data, e) => {
        e.preventDefault();
        createTickets(data, e);
    };

    return (
        <div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {price > 0 && paymentMethods.map((method) => {
                                const Component = method.component;

                                return (
                                    <Disclosure as="div" key={method.method} className="pt-6 method-box">
                                        {({open}) => (
                                            <>
                                                <dt className="text-lg">
                                                    <Disclosure.Button
                                                        className="text-left w-full flex justify-between items-start text-gray-400">
                                                        <span className="font-medium text-gray-900">{method.method}</span>
                                                        <span className="ml-6 h-7 flex items-center">
                                                          <ChevronDownIcon
                                                              className={classNames(open ? '-rotate-180' : 'rotate-0', ' h-6 w-6 transform')}
                                                              aria-hidden="true"
                                                          />
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                    <Component />
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                )
                            }
                        )}

                        { price <= 0 || ! station || ! seats && <p className="mt-2 text-sm text-red-600">Pris saknas eller är 0kr.</p>}

                        {
                            price === 0 && station && seats && travelers &&
                            <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
                                <AcceptTerms/>

                                <button
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    disabled={processing}
                                    id="submit"
                                >
                                    <span id="button-text">
                                      {processing ? (
                                          <Spinner />
                                      ) : (
                                          "Slutför bokningen"
                                      )}
                                    </span>
                                </button>
                            </form>
                        }
                    </dl>
                </div>
            </div>
        </div>
    )
}
